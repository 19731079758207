import React, { useContext, useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import BigInteger from 'big-integer';
import { authContext } from './../../contexts/AuthProvider';
import { useSelector, history } from "../../stores";
import {numberWithDelimiter} from "../../utils";
import {totalPrice as calcTotalPrice, emptyCard} from "../../components/cart/CartController";
import {AddressComponent, newEmptyAddress} from "../../components/address/AddressComponent";
import {Payment} from "../../components/stripe/Payment";
import LinkButton from "../../components/LinkButton";
import {updateCheckoutItem, fetchDeliveryTimeZone, fetchShippingFee, fetchTaxRate} from "./CheckoutController";
import styles from "./CheckoutPage.module.scss"
import { paginate, finishMounted } from "./../../stores/transition";
import CheckoutForm, { ValueInterface } from "./../../components/checkout/CheckoutForm";
import MemoForm from "./../../components/checkout/MemoForm";
import DeliveryDateForm, { IDeliveryDateForm } from "./../../components/delivery-date/DeliveryDateForm";
import Bank from "./../../components/checkout/Bank";
import Cod from "./../../components/checkout/Cod";
import RegisteredCard from "./../../components/checkout/RegisteredCard";
import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import Bugsnag from '@bugsnag/js'

enum PaymentMethod {
  Credit = 'credit',
  RegisteredCredit = 'registered_credit',
  Bank = 'bank',
  Cod = 'cod',
}

const COD_PRICE_LIMIT = 300000;

export const CheckoutPage = () => {
    const dispatch = useDispatch();
    const auth = useContext(authContext);
    const items = useSelector(state => state.checkout.items);
    const shippingFee = useSelector(state => state.checkout.shippingFee);
    const taxRate = useSelector(state => state.checkout.taxRate);
    const timezones = useSelector(state => state.checkout.timezones);
    const paymentMethodFee = useSelector(state => state.checkout.paymentMethodFee);
    const [selectableCod, setSelectableCod] = useState<boolean>(true);
    const [address, setAddress] = useState(newEmptyAddress());
    const [memo, setMemo] = useState('');
    const [card, setCard] = useState();
    const [errors, setErrors] = useState([]);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [formValues, setFormValues] = useState<ValueInterface>({ name: auth.user?.user_name ?? '', email: auth.user?.email || '', phone: auth.user?.tel || '' });
    const [deliveryDateValues, setDeliveryDateValues] = useState<IDeliveryDateForm>({ desired_delivery_date: undefined, desired_delivery_timezone: '' });
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.Credit);

    const total = calcTotalPrice(items);
    // 丸目誤差が発生するので整数に変換してからceilする
    const tax = total ? Math.ceil((total + shippingFee + paymentMethodFee) * taxRate) : 0;
    const subTotal = total ? Math.ceil(total + shippingFee + paymentMethodFee) : 0;
    const totalPrice = total ?
      BigInteger(total)
        .plus(BigInteger(shippingFee))
        .plus(BigInteger(paymentMethodFee))
        .plus(BigInteger(tax))
        : 0;

    if (isMounted) dispatch(finishMounted());
    useEffect(() => {
        (async () => {
            await Promise.all([
              dispatch(fetchShippingFee(items.map(item => item.id))),
              dispatch(fetchTaxRate()),
              dispatch(fetchDeliveryTimeZone()),
              initAddress(),
              initCard(),
            ]);
            setIsMounted(true);
        })();
    }, []);

    useEffect(() => {
        if (paymentMethod !== PaymentMethod.Cod) return;
        if (totalPrice < COD_PRICE_LIMIT) return;
        setPaymentMethod(PaymentMethod.Credit);
        setSelectableCod(false);
        alert(`合計金額は${totalPrice}円と、${COD_PRICE_LIMIT}円を超えるため代引きは選択できません。`);
    }, [paymentMethodFee]);

    const initAddress = async (): Promise<void> => {
      if (!auth.user) return;
      const res = await axios.get('/api/address');
      setAddress(old => {
        if (res.data?.country) old.country.code = res.data.country;
        if (res.data?.zip) old.zip = res.data.zip;
        if (res.data?.address) old.address = res.data.address;
        if (res.data?.building_name) old.building_name = res.data.building_name;
        return {...old};
      });
    };

    const initCard = async (): Promise<void> => {
      if (!auth.user) return;
      const res = await axios.get('/api/cards');
      setCard(res.data?.card);
    };

    const onPaymentMethodReady = async (stripe, payment?: StripePaymentMethod) => {
        try {
            const params = {items, memo, payment, address, payment_method: paymentMethod, ...formValues, ...deliveryDateValues};
            const res = await axios.post('/api/checkout', params);
            if (res.data.require_action) {
                const { error: errorAction, paymentIntent } = await stripe.handleCardAction(res.data.payment_intent_client_secret);
                if (errorAction) {
                    console.error(errorAction);
                    throw new Error(errorAction);
                }

                await axios.post('/api/checkout', { ...params, payment_intent_id: paymentIntent.id })
            }

            dispatch(emptyCard());
            dispatch(paginate('/checkout/complete'));
        } catch(err) {
            if (err.response?.status !== 422) {
              throw err;
            }
            handleErrors(err.response?.data?.errors);
        }
    };

    const handleErrors = errors => {
        errors?.map((error, i) => {
            if (error?.messages?.inventory && items?.[i]) {
                dispatch(updateCheckoutItem([items[i], error.messages.available]));
            }
        });
        setErrors(errors);
        setShowErrorDialog(true);
    };

    const printError = error => {
        if (error?.messages?.inventory) {
            return error.messages.inventory + '';
        } else if (error?.messages) {
            return error.messages + '';
        }

        return error;
    }

    const closeDialog = setShowErrorDialog.bind(null, false);

    const handleOnChangeform = (values: ValueInterface): void => setFormValues({...values});

    const handleOnSubmitBank = async (e: React.FormEvent): Promise<void> => {};

    const handleOnChangeMemo = (e: React.ChangeEvent<HTMLInputElement>): void => setMemo(e.target.value);

    const onChangePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>): void =>
      setPaymentMethod(e.currentTarget.value as PaymentMethod);

    return <div className="container">
        <table className="table table-striped">
            <tbody>
                {
                    items.map((item, i) =>
                        <tr key={`checkout-detail-item-${i}`} className={item.amount ? '' : styles.unavailableItem}>
                            <td>{item.name}</td>
                            <td className={styles.price}>{numberWithDelimiter(item.price)}円</td>
                            <td className={styles.price}>{item.amount}本</td>
                            <td className={styles.price}>{numberWithDelimiter(item.price * item.amount)}円</td>
                        </tr>)
                }
                <tr>
                    <td/>
                    <td/>
                    <td>Shipping</td>
                    <td className={styles.price}>{numberWithDelimiter(totalPrice ? shippingFee : 0)}円</td>
                </tr>
                <tr>
                    <td/>
                    <td/>
                    <td>Payment method fee</td>
                    <td className={styles.price}>{numberWithDelimiter(totalPrice ? paymentMethodFee : 0)}円</td>
                </tr>

                <tr>
                    <td/>
                    <td/>
                    <td>Subtotal</td>
                    <td className={styles.price}>{numberWithDelimiter(subTotal)}円</td>
                </tr>
                <tr>
                    <td/>
                    <td/>
                    <td>Tax</td>
                    <td className={styles.price}>{numberWithDelimiter(tax)}円</td>
                </tr>
                <tr>
                    <td/>
                    <td/>
                    <td>Total</td>
                    <td className={styles.price}>{numberWithDelimiter(totalPrice)}円</td>
                </tr>
            </tbody>
        </table>
        {
            total > 0 &&
            <>
              <Form.Group>
                <Form.Label> 支払方法 </Form.Label>
                <Form.Check type="radio"
                  custom
                  label="クレジットカード"
                  id="paymentCredit"
                  name="paymentMethod"
                  value={PaymentMethod.Credit}
                  onChange={onChangePaymentMethod}
                  className={styles.check}
                  checked={paymentMethod === PaymentMethod.Credit} />
                <Form.Check type="radio"
                  custom
                  label="登録済みクレジットカード"
                  id="paymentRegisteredCredit"
                  name="paymentMethod"
                  value={PaymentMethod.RegisteredCredit}
                  onChange={onChangePaymentMethod}
                  className={styles.check}
                  disabled={!card}
                  checked={paymentMethod === PaymentMethod.RegisteredCredit} />
                <Form.Check type="radio"
                  custom
                  label="銀行振込"
                  id="paymentBank"
                  name="paymentMethod"
                  value={PaymentMethod.Bank}
                  onChange={onChangePaymentMethod}
                  className={styles.check}
                  checked={paymentMethod === PaymentMethod.Bank} />
                <Form.Check type="radio"
                  custom
                  label="代引き"
                  id="paymentCod"
                  name="paymentMethod"
                  value={PaymentMethod.Cod}
                  onChange={onChangePaymentMethod}
                  className={styles.check}
                  disabled={(totalPrice > COD_PRICE_LIMIT) || !selectableCod}
                  checked={paymentMethod === PaymentMethod.Cod} />
                <small className="text-muted">
                  ※総額が30万円以上の場合、代引きはご利用いただけません。<br/>
                  ※代引き手数料はお客様負担となります。(料金については<a className={styles.link} href="https://faq.kuronekoyamato.co.jp/app/answers/detail/a_id/1579/~/%E3%80%8C%E5%AE%85%E6%80%A5%E4%BE%BF%E3%82%B3%E3%83%AC%E3%82%AF%E3%83%88%E3%80%8D%E3%81%AE%E4%BB%A3%E9%87%91%E5%BC%95%E6%8F%9B%28%E4%BB%A3%E5%BC%95%E3%81%8D%29%E6%89%8B%E6%95%B0%E6%96%99%E3%82%92%E3%80%81%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82" target="_blank">こちら</a>)
                </small>
              </Form.Group>

              {paymentMethod === PaymentMethod.Credit &&
                  <Payment onPaymentMethodReady={onPaymentMethodReady}
                      {...formValues}
                      topChildren={<>
                        <CheckoutForm onChange={handleOnChangeform} {...formValues} />
                        <AddressComponent onAddressChange={setAddress} address={address} required />
                        <DeliveryDateForm timezones={timezones} onChange={setDeliveryDateValues} values={deliveryDateValues} />
                      </>}
                      bottomChildren={<MemoForm value={memo} onChange={handleOnChangeMemo} />} />
              }
              {paymentMethod === PaymentMethod.RegisteredCredit &&
                  <RegisteredCard onSubmit={e => onPaymentMethodReady()} card={card}
                      topChildren={<>
                        <CheckoutForm onChange={handleOnChangeform} {...formValues} />
                        <AddressComponent onAddressChange={setAddress} address={address} required />
                        <DeliveryDateForm timezones={timezones} onChange={setDeliveryDateValues} values={deliveryDateValues} />
                      </>}
                      bottomChildren={<MemoForm value={memo} onChange={handleOnChangeMemo} />} />
              }
              {paymentMethod === PaymentMethod.Bank &&
                <Bank onSubmit={e => onPaymentMethodReady()}
                    topChildren={<>
                      <CheckoutForm onChange={handleOnChangeform} {...formValues} />
                      <AddressComponent onAddressChange={setAddress} address={address} required />
                      <DeliveryDateForm timezones={timezones} onChange={setDeliveryDateValues} values={deliveryDateValues} />
                    </>}
                    bottomChildren={<MemoForm value={memo} onChange={handleOnChangeMemo} />} />
              }
              {paymentMethod === PaymentMethod.Cod && (
                <Cod onSubmit={e => onPaymentMethodReady()} price={totalPrice}>
                    <CheckoutForm onChange={handleOnChangeform} {...formValues} />
                    <AddressComponent onAddressChange={setAddress} address={address} required />
                    <DeliveryDateForm timezones={timezones} onChange={setDeliveryDateValues} values={deliveryDateValues} />
                    <MemoForm value={memo} onChange={handleOnChangeMemo} />
                </Cod>
              )}
            </>
        }
        {
            total === 0 &&
            <>
                <p className="mb-3">カートは空です</p>
                <LinkButton to="/" className="w-100">戻る</LinkButton>
            </>
        }

        <Modal show={showErrorDialog} backdrop="static"
               onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title>エラー</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    {
                        errors.map((error, i) =>
                            <li key={`error-${i}`} className="text-danger">{printError(error)}</li>)
                    }
                </ul>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>OK</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    </div>;
};
